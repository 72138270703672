import type { I18nOptions } from 'vue-i18n'
import type { App } from 'vue'
import { merge } from 'lodash'
import { createI18n } from 'vue-i18n'

async function createI18nOptions(): Promise<I18nOptions> {
  // const locale = import.meta.env.VITE_APP_LANG
  const locale = getLocale()
  document.querySelector('html')?.setAttribute('lang', locale)
  // 新版国际化Message
  // @ts-ignore
  const modules = import.meta.glob('./languages/**/*.json', { eager: true })
  let message = {}
  for (const k of Object.keys(modules)) {
    const moduleName: any[] = k.replace('./languages/', '').split('/')
    if (!k.includes(locale)) continue
    const modulePromise = modules[k] as Promise<{
      default: any
    }>
    const value = (await modulePromise).default
    if (moduleName.length >= 2) {
      moduleName[moduleName.length - 1] = value
      message = {
        ...message,
        ...merge(moduleName.reduceRight((a, b) => ({ [b]: a })))
      }
    }
  }

  return {
    legacy: false,
    locale,
    // fallbackLocale: 'zh-CN',
    fallbackLocale: 'en-US',
    messages: {
      [locale]: message
    },
    globalInjection: true
  }
}

function getLocale(): string {
  const params = new URLSearchParams(window.location.search);
  const queryObject = Object.fromEntries(params.entries());
  // 数据集分享页面中英文根据query判断
  if(window.location.pathname.includes('/share/dataset/list') && queryObject.env === 'en') {
    return 'en-US'
  }
  return 'zh-CN'
}

export let i18n: ReturnType<typeof createI18n>
export default async function setupI18n(app: App): Promise<void> {
  const options = await createI18nOptions()
  i18n = createI18n({
    ...options,
    missing: (locale, key, vm) => ''
  }) as any
  app.use(i18n)
}
